import React from 'react';
import { Link } from 'gatsby';
import {
  FaTwitter,
  FaCodepen,
  FaLinkedin,
  FaGithub,
  FaRss,
  FaInstagram,
  FaFacebook,
} from 'react-icons/fa';

const Footer = () => (
  <footer className="bg-primary" aria-labelledby="footerHeading">
    <h2 id="footerHeading" className="sr-only">
      Footer
    </h2>
    <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-6 lg:px-8 border-t border-primary">
      <div className="xl:grid xl:grid-cols-2 xl:gap-8">
        <div className="w-full">
          <div className="w-full">
            <div>
              <h3 className="text-sm font-semibold text-primary tracking-wider uppercase">
                Pages
              </h3>
              <ul className="mt-4 space-y-4">
                <li>
                  <Link
                    className="text-base text-footer-link hover:text-footer-link-hover"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-base text-footer-link hover:text-footer-link-hover"
                    to="/case-study-am"
                  >
                    Case-Study/AM 
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-base text-footer-link hover:text-footer-link-hover"
                    to="/case-study-metavaders"
                  >
                    Case-Study/Metavaders
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-base text-footer-link hover:text-footer-link-hover"
                    to="/case-study-automation"
                  >
                    Case-Study/Boook
                  </Link>
                </li>
                <li>
                  <Link
                    className="text-base text-footer-link hover:text-footer-link-hover"
                    to="/case-study-cioffilawfirm"
                  >
                    Case-Study/CioffiLawFirm
                  </Link>
                </li>
                
              
              </ul>
            </div>
          
          </div>
          <div className="md:grid md:grid-cols-2 md:gap-8" />
        </div>
      </div>
    </div>

    <div className="mt-2 py-8 px-4 sm:px-6 lg:px-8 md:flex md:items-center md:justify-between">
      <div className="flex space-x-6 md:order-2">
        <a
          href="https://facebook.com/christosmalamas1"
          className="text-footer-icon hover:text-footer-icon-hover"
          target="_blank"
        >
          <FaFacebook className="h-5 w-5" />
        </a>

        <a
          href="https://github.com/christos16"
          className="text-footer-icon hover:text-footer-icon-hover"
          target="_blank"
        >
          <span className="sr-only">GitHub</span>
          <FaGithub className="h-5 w-5" />
        </a>

        <a
          href="https://instagram.com/christos_malamas"
          className="text-footer-icon hover:text-footer-icon-hover"
          target="_blank"
        >
          <span className="sr-only">Instagram</span>
          <FaInstagram className="h-5 w-5" />
        </a>

        <a
          href="https://www.linkedin.com/in/christos-malamas-580411189/"
          className="text-footer-icon hover:text-footer-icon-hover"
          target="_blank"
        >
          <span className="sr-only">LinkedIn</span>
          <FaLinkedin className="h-5 w-5" />
        </a>
    
      </div>
      <p className="mt-8 text-base text-footer-icon md:mt-0 md:order-1">
        &copy; {new Date().getFullYear()} Christos Malamas. All rights reserved.
      </p>
    </div>
  </footer>
);

export default Footer;
